@font-face {
    font-family: 'Paris2024-Black';
    src: url('../fonts/web/statiques/woff/Paris2024-Black.woff') format('woff');    
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Paris2024-Bold';
    src: url('../fonts/web/statiques/woff/Paris2024-Bold.woff') format('woff');    
    font-weight: bold;
    font-style: normal;
}

*{
    font-family: 'Paris2024-Bold' !important;
    font-size: 24px;    
}

#root{
    background-image: url('../img/64-64-crop.png');
}

.qr-background{
    background-image: url('../img/Grupo\ 650.png');
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100vh;
    overflow: hidden;
}

.botellas-img{
    max-width: 380px;
}
  
.first-background{
    background-image: url('../img/Grupo\ 645.png');
}
.other-background{
    background-image: url('../img/Grupo\ 644.png');
    background-position: center top 0px !important;
    background-repeat: no-repeat;
}
.img-left{
    position: absolute;
    top: -200px;
    height: calc(100vh + 350px);
    left: -200px;
}
.img-right{
    position: absolute;
    top: -200px;
    height: calc(100vh + 350px);
    right: -200px;
}
.img-botella-left{
    position: absolute;
    top: 0px;
    height: calc(100vh + 350px);
    left: 0px;
}
.img-botella-right{
    position: absolute;
    top: 0px;
    height: calc(100vh + 350px);
    right: 0px;
}

.mt-0{
    margin-top: 0 !important;
}
.mb-10{
    margin-bottom: 10px !important;
}
.camara-img{
    margin-bottom: 50px;
}

.horizontal-logo{
    margin-bottom: 5%;
    max-width: 360px;
}

.step1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 40vh;
    align-items: center;
}

.container-horizontal-logo{
    margin-bottom: 25px;
}

.h-70vh{
    height: 70vh;    
}

.text-center{
    text-align: center;
}

.button-logo-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 10px;
}

.main-container{
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100vh;
    overflow: hidden;
}

h4.title{
    color: #fff;
    font-size: 30px;
}

h3.title{
    color: #fff;
}

.flex-column{
    flex-direction: column;
}

.union-frutas{
    display: flex;
    position: relative;
    width: 485px;
}

.union-frutas img{    
    position: absolute;
    width: 150px; 
    height: auto;
    bottom: -25px;
    z-index: 1;
}

.union-frutas img:nth-child(1) {    
    left: 160px;
    bottom: -35px;
}

.union-frutas img:nth-child(2) {    
    right: 90px;
}

.wizard-container{
    background-repeat: repeat;
    height: 400px;
    max-width: 700px !important;
    border-radius: 400px 400px 0 0;
}

.vertical-horizontal-center{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.vertical-logo{
    position: absolute;
    top: 5%;
    right: 5%;
    max-height: 370px;
}

.wizard-button{
    color: green !important;   
    background-image: url('../img/Grupo\ 550.png'); 
    background-size: 100% 100%;
    width: 600px !important;
    height: 75px;
}

.wizard-button:hover, .wizard-button:focus, .wizard-button:active{
    background-color: transparent !important;
}

.radio-selection{
    margin: 5px !important;
    width: 335px !important;
    border-radius: 35px !important;
    border: 2px solid #fff !important;
    color: green !important;
    background: #fff !important;
    margin: 10px !important;
    font-size: 35px !important;
}

.Mui-selected{
    background-color: green !important;
    color:#fff !important;
}

.image-uploader{
    align-items: center;
    justify-content: end !important;
}

.resultImageContainer{
    width: 100%;
    max-width: 400px;
}

.resultImageContainer img {
    width: 100%;
    height: auto;
    display: block;
}

.qr-container{
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;
    padding: 25px;
    width: 130%;
    margin-bottom: 40%;
}

.h-80vh{
    height: 80vh;
}

.container-title-green{
    position: absolute;
    bottom: 5px;
}

.qr-container .text{
    margin-left: 25px;
}

.img-qr-flecha{
    width:35%;
    position: absolute;
    bottom: -90px;
    right: 50px;

}

.img-qr-sillas{    
    position: absolute;
    left: 20%;
    width: 30%;
    top: 15%;
    z-index: 1;
}

.img-qr-rayas{    
    position: absolute;
    top: 65%;
    left: 20%;
    width: 5%;
    z-index: 1;
}

.img-qr-result {
    width: 300px;
    background-color: white;
    padding: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    transform: rotate(-15deg);
    top: -20%;
    z-index: 1;
}

.img-qr-result img {
    width: 100%;
    object-fit: contain;
}

.img-qr-botellas{
    position: absolute;
    right: 10%;
    bottom: 5%;
    width: 15%;
    max-width: 300px;
}

.title-green{
    color: green;
}

h4.title-green{
    font-size: 18px;
    margin-top: 0 !important;
    margin-bottom: 5px !important;
    text-align: left;
}

h3.title-green{
    font-size: 27px;
    text-align: center;
}

.img-sillas-indicators{
    position: absolute;
    left: 15%;
    width: 30%;
    top: 30%;
    z-index: 1;
}

.img-camera-steps{
    position: absolute;
    right: 32%;
    bottom: 15%;
    width: 15%;
    max-width: 150px;
}

.img-indicators-steps{
    position: absolute;
    right: 37%;
    top: 50%;
    z-index: 1;
    max-width: 150px;
}
.preview-overlay{
    width: 640px;
    height: 480px;
    overflow: hidden;
}
.preview-image {
    width: 100%;
    height: auto;
    object-fit: contain;
}
.image-container {
  width: 200px;
  background-color: white;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  transform: rotate(-15deg);
  margin-top: 30px;
  margin-bottom: 30px;
}

.image-container img {
  width: 100%;
  object-fit: contain; 
}

.image-container img:nth-child(2) {    
  margin-top: 20px;
}

.p-0{
  padding: 0 !important;
}

.qr-page{
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 380px;
}

.w-100{
    width: 100% !important;
}

.m-w-100{
    max-width: 100% !important;
}

.button-title{
    font-size: 43px;
}

.text-page3{
    font-size: 55px !important;
}

.text-title-page3{
    font-size: 45px !important;
}